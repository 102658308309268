import localforage from 'localforage';

export default {

    put: async (key, data) => localforage.setItem(key, data),

    get: async key => localforage.getItem(key),

    remove: async key => localforage.removeItem(key),

    clear: () => localforage.clear(),
}