import {
    FETCH_MODULES,
    FETCH_MODULES_FULFILLED,
    FETCH_MODULES_REJECTED
} from './moduleActions';

const defaultState = {
    isLoading: false,
    modules: []
}

export const modules = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case FETCH_MODULES: {
            return {
                ...state,
                isLoading: true
            }
        }
        case FETCH_MODULES_FULFILLED: {
            return {
                ...state,
                isLoading: false,
                modules: action.modules
            }
        }
        case FETCH_MODULES_REJECTED: {
            return {
                ...state,
                isLoading: false
            }
        }
        default: {
            return state;
        }
    }
};