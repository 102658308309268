import HttpError from './HttpError';
import CacheManager from '../cache/CacheManager';
import CacheManagerProps from '../cache/CacheManagerProps';
import { authService } from '../../data/auth/authService';

export const ENVIRONMENTS = {
    EU: 'eu',
    AU: 'au'
}

export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const API_ROOT_AU = process.env.REACT_APP_API_ROOT_AU;

const getAPIRoot = async environment => {
    if (environment === ENVIRONMENTS.EU) {        
        return Promise.resolve(API_ROOT);
    }
    if (environment === ENVIRONMENTS.AU) {        
        return Promise.resolve(API_ROOT_AU);
    }

    const env = await authService.getEnvironment();

    if (env === ENVIRONMENTS.AU) {
        return API_ROOT_AU;
    }

    return API_ROOT;
}

export const get = async (url, env) => {
    const API_ROOT = await getAPIRoot(env);
    const token = await CacheManager.get(CacheManagerProps.TOKEN);

    const resp = await fetch(`${API_ROOT}${url}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed to get ${url}`);
    }

    return await resp.json();
}

export const post = async (url, data) => {
    const API_ROOT = await getAPIRoot();
    const token = await CacheManager.get(CacheManagerProps.TOKEN);

    const resp = await fetch(`${API_ROOT}${url}`, {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
    });

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed to post to ${url}`);
    }

    return await resp.json();
}

export const put = async (url, data) => {
    const API_ROOT = await getAPIRoot();
    const token = await CacheManager.get(CacheManagerProps.TOKEN);

    const resp = await fetch(`${API_ROOT}${url}`, {
        method: 'PUT',
        redirect: 'follow',
        headers: {
            'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
    });

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed to put to ${url}`);
    }

    return await resp.json();
}


export const head = async (url, token) => {
    const API_ROOT = await getAPIRoot();

    const resp = await fetch(`${API_ROOT}${url}`, {
        method: 'HEAD',
        redirect: 'follow',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': `Bearer ${token}`
        }
    })

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed head to ${url}`);
    }

    return resp;
}

export const patch = async (url, data) => {
    const API_ROOT = await getAPIRoot();
    const token = await CacheManager.get(CacheManagerProps.TOKEN);

    const resp = await fetch(`${API_ROOT}${url}`, {
        method: 'PATCH',
        redirect: 'follow',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed patch to ${url}`);
    }

    return resp;

}

export const deletee = async url => {
    const API_ROOT = await getAPIRoot();
    const token = await CacheManager.get(CacheManagerProps.TOKEN);

    const resp = await fetch(`${API_ROOT}${url}`, {
        method: 'DELETE',
        redirect: 'follow',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': `Bearer ${token}`
        }
    })

    if (resp.status !== 200) {
        throw new HttpError(resp.status, `Failed to delete ${url}`);
    }

    return resp;
}
