import React from 'react';
import { NavLink } from 'react-router-dom';
import Loader from '../../common/loader';

export const DashboardCard = ({
    to,
    extraLinks,
    text,
    children,
    isLoading,
    style,
    headerExtra,
}) => (
    <div
        className="man-bo-dashboard-item"
        style={{ opacity: isLoading ? 0.5 : 1, ...style }}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}>
            <h4>{text}</h4>
            {headerExtra}
        </div>
        {children}

        {(extraLinks || []).map(({ href, title }) => (
            <NavLink to={href} key={href}>
                <h5>{title}</h5>
            </NavLink>
        ))}
        
        {to && (
            <NavLink to={to}>
                <h5>View all</h5>
            </NavLink>
        )}
        {isLoading && <Loader />}
    </div>
);
