import {
    FETCH_INSTANCES,
    FETCH_INSTANCES_FULFILLED,
    FETCH_INSTANCES_REJECTED
} from './instanceActions';

const defaultState = {
    isLoading: false,
    instances: []
}

export const instances = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case FETCH_INSTANCES: {
            return {
                ...state,
                isLoading: true
            }
        }
        case FETCH_INSTANCES_FULFILLED: {
            return {
                ...state,
                isLoading: false,
                instances: action.instances
            }
        }
        case FETCH_INSTANCES_REJECTED: {
            return {
                ...state,
                isLoading: false
            }
        }
        default: {
            return state;
        }
    }
};