import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';

const Breadcrumb = ({ to, text }) => (
    <span>
        <i className="right angle icon divider"></i>
        <NavLink to={to}
            className="section">
            {text}
        </NavLink>
    </span>

);

const Breadcrumbs = ({ history }) => {
    const paths = history.location.pathname.split('/')
        .filter(path => path)
        .map((path, index, arr) => ({
            path: arr.slice(0, index + 1).join('/'),
            text: path
        }));

    return (
        <div className="ui breadcrumb"
            style={{ marginBottom: 20 }}>
            <NavLink to="/"
                className="section">
                DASHBOARD
            </NavLink>

            {paths.map(path => (
                <Breadcrumb key={path.path}
                    to={`/${path.path}`}
                    text={path.text.toUpperCase()} />
            ))}
        </div>
    );
};

export default withRouter(Breadcrumbs);