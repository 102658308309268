import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchInstances } from '../../../../data/instances/instanceActions';
import { DashboardCard } from '../DashboardCard';

const InstanceCard = ({ instances, isLoadingInstances, fetchInstances }) => {
    useEffect(() => {
        instances.length === 0 && fetchInstances();
    }, []);

    return (
        <DashboardCard
            text="Instances"
            isLoading={isLoadingInstances}
            to="/instances"
            extraLinks={[{ title: 'View licenses', href: '/billing' }]}>
            <div>
                <h1>{instances.length}</h1>
            </div>
        </DashboardCard>
    );
};

const mapStateToProps = state => ({
    instances: state.instances.instances,
    isLoadingInstances: state.instances.isLoading,
});

const mapDispatchToProps = {
    fetchInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceCard);
