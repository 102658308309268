import React, { useState } from 'react';
import { integrationsService } from '../../../../data/integrations/integrationsService';
import { Form, Input, Button, Table } from 'semantic-ui-react';

const Logs = ({ response }) => {
    if (!response) {
        return null;
    }

    const { Items, Count, ScannedCount } = response;

    return (
        <Table className="man-form-table" celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={5}>
                        {`${Count || 0} items / ${ScannedCount || 0} scanned`}
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>ErpId</Table.HeaderCell>
                    <Table.HeaderCell>Timestamp</Table.HeaderCell>
                    <Table.HeaderCell>Address</Table.HeaderCell>
                    <Table.HeaderCell>ItemId</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Items && Items
                    .map(item => (
                        <Table.Row key={`${item.Id}-${item.Timestamp}`}>
                            <Table.Cell>
                                {item.ErpId}
                            </Table.Cell>
                            <Table.Cell>
                                {item.Timestamp}
                            </Table.Cell>
                            <Table.Cell>
                                {item.Address}
                            </Table.Cell>
                            <Table.Cell>
                                {item.ItemId}
                            </Table.Cell>
                        </Table.Row>
                    ))}
            </Table.Body>
        </Table>
    )
}

const InstanceIntegrationLogs = ({ id }) => {

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState([]);
    const [limit, setLimit] = useState(10);

    const fetchLogs = async () => {
        try {
            setLoading(true);

            const res = await integrationsService.fetchLogs(id, limit);

            setResponse(res);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>

            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <Input
                            type="number"
                            placeholder="Limit (10)"
                            value={limit}
                            onChange={(e, { value }) => setLimit(value)} />
                    </Form.Field>
                    <Form.Field>
                        <Button fluid
                            color="instagram"
                            loading={loading}
                            onClick={fetchLogs}>
                            Search
                        </Button>
                    </Form.Field>
                    <Form.Field></Form.Field>
                    <Form.Field></Form.Field>

                </Form.Group>

            </Form>

            <div>
                <Logs response={response} />
            </div>

        </div>
    )
}

export default InstanceIntegrationLogs;