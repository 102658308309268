import React, { useState, useEffect } from 'react';
import { instanceService } from '../../../data/instances/instanceService';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import InstanceForm from './InstanceForm';
import AclForm from './AclForm';
import UserForm from './UserForm';
import AppModulesForm from './AppModulesForm';
import InstanceStats from './stats';
import InstanceIntegrationLogs from './integration-logs';
import SequencesForm from './SequencesForm';

const Instance = ({ match }) => {
    const id = match.params.id;

    const [seq, setSeq] = useState(1);
    const [details, setDetails] = useState(null);
    const [users, setUsers] = useState([]);
    const [acls, setAcls] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const onSaveDetails = async () => {
        console.log(details);

        if (!window.confirm(JSON.stringify(details))) {
            return;
        }

        try {
            await instanceService.update(details);
        } catch (err) {
            console.error(err);
            console.log('error');
            alert(err);
        }
    };

    const onChangeDetails = (name, value) => {
        setDetails({
            ...details,
            [name]: value,
        });
    };

    const onSaveUsers = () => {
        console.log(users);

        if (!window.confirm(JSON.stringify(users))) {
            return;
        }
    };

    const onChangeUsers = u => {
        setUsers(u);
    };

    const onSaveAcls = () => {
        console.log(acls);

        if (!window.confirm(JSON.stringify(acls))) {
            return;
        }
    };

    const onChangeAcls = a => {
        setAcls(a);
    };

    const handleReload = () => {
        setSeq(s => s + 1);
    };

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const instance = await instanceService.fetchDetails(id);

                setDetails(instance);
                setAcls(instance.acls);
                setUsers(instance.users);
            } catch (err) {
                console.error(err);
            }
        };

        fetchDetails();
    }, [seq]);

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const permissions = await instanceService.fetchPermissions(id);

                setPermissions(permissions);
            } catch (err) {
                console.error(err);
            }
        };

        fetchPermissions();
    }, []);

    if (!details) {
        return <div>Loading...</div>;
    }

    const panes = [
        {
            menuItem: 'Details',
            render: () => (
                <Tab.Pane attached={false}>
                    <InstanceForm
                        {...details}
                        onChange={onChangeDetails}
                        onSave={onSaveDetails}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Statistics',
            render: () => (
                <Tab.Pane attached={false}>
                    <InstanceStats {...details} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Modules',
            render: () => (
                <Tab.Pane attached={false}>
                    <AppModulesForm
                        {...details}
                        onAppModulesUpdated={handleReload}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Access levels',
            render: () => (
                <Tab.Pane attached={false}>
                    <AclForm
                        acls={acls}
                        onChange={onChangeAcls}
                        onSave={onSaveAcls}
                        permissions={permissions}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Users',
            render: () => (
                <Tab.Pane attached={false}>
                    <UserForm
                        instanceId={id}
                        users={users}
                        onChange={onChangeUsers}
                        onSave={onSaveUsers}
                        acls={acls}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Sequences',
            render: () => (
                <Tab.Pane attached={false}>
                    <SequencesForm
                        {...details}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Logs',
            render: () => (
                <Tab.Pane attached={false}>
                    <InstanceIntegrationLogs {...details} />
                </Tab.Pane>
            ),
        },
    ];

    return (
        <div style={{ width: '100%', marginBottom: 20 }}>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
    );
};

export default withRouter(Instance);
