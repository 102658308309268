import React, { useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Flag, Input, Table } from 'semantic-ui-react';
import { instanceService } from '../../../data/instances/instanceService';
import { ENVIRONMENTS } from '../../../utils/http/HttpClient';

const InstanceRow = ({
    id,
    name,
    activeUsers,
    activeLicenses,
    totalPrice,
    crmActiveUsers,
    crmActiveLicenses,
}) => {
    const licenseDiff = getDiff(
        activeUsers,
        activeLicenses
    );
    const crmDiff = getDiff(
        crmActiveUsers,
        crmActiveLicenses
    );

    return (
        <Table.Row key={id}>
            <Table.Cell>
                {name}{' '}
                <span className="trks-secondary-text">
                    <NavLink
                        to={`/instances/${id}`}>
                        {id}
                    </NavLink>
                </span>
                <div className="trks-secondary-text">
                    {formatPriceSum(
                        totalPrice,
                        'kr'
                    )}
                </div>
            </Table.Cell>
            <Table.Cell>
                {activeUsers}/{activeLicenses}
                <span
                    style={{
                        color: licenseDiff.diffColor,
                        fontWeight: 700,
                    }}>
                    {licenseDiff.diffText}
                </span>
            </Table.Cell>
            <Table.Cell>
                {crmActiveUsers}/{crmActiveLicenses}
                <span
                    style={{
                        color: crmDiff.diffColor,
                        fontWeight: 700,
                    }}>
                    {crmDiff.diffText}
                </span>
            </Table.Cell>
        </Table.Row>
    );
}

const formatDecimals = (value, separator = ' ') => {
    if (!value) {
        return '';
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

const formatPriceSum = (value, currency) => {
    let val = parseFloat(value || 0);

    if (isNaN(val)) {
        val = 0;
    }

    return `${formatDecimals(val.toFixed(0))}${currency ? ` ${currency}` : ''}`;
};

const getDiff = (active, licenses) => {
    const diff = licenses - active;

    let diffText = '';
    let diffColor = 'green';

    if (diff > 0) {
        diffText = ` +${diff}`;
    }

    if (diff < 0) {
        diffText = ` ${diff}`;
        diffColor = 'red';
    }

    return {
        diffText,
        diffColor,
    };
};

const Billing = ({ modules, fetchModules }) => {
    const [billingState, setBillingState] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchBilling = async () => {
            try {
                const { instances } = await instanceService.fetchBilling();

                console.log(instances)
                setBillingState(instances);
            } catch (err) {
                alert(err);
            }
        };

        fetchBilling();
    }, []);

    const instances = billingState.filter(({ name, id }) => {
        if (search === '') {
            return true;
        }

        return (
            (name || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (id || '').toLowerCase().indexOf(search.toLowerCase()) > -1
        );
    });

    const euInstances = instances
        .filter(i => i.environment === ENVIRONMENTS.EU);

    const auInstances = instances
        .filter(i => i.environment === ENVIRONMENTS.AU);

    const euTotalPrice = euInstances.reduce((acc, val) => acc + val.totalPrice, 0);
    const auTotalPrice = auInstances.reduce((acc, val) => acc + val.totalPrice, 0);

    return (
        <React.Fragment>
            <div style={{ marginBottom: 10 }}>
                <Input
                    autoFocus
                    icon="search"
                    iconPosition="left"
                    placeholder="Search..."
                    value={search}
                    onChange={(e, { value }) => setSearch(value)}
                />
            </div>

            <Table className="man-form-table" celled selectable compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Instance</Table.HeaderCell>
                        <Table.HeaderCell>
                            Trukks Core
                            <div className="trks-secondary-text">
                                Users / Licenses
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            CRM
                            <div className="trks-secondary-text">
                                Users / Licenses
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Flag name="eu" />

                            <b>
                                {formatPriceSum(
                                    euTotalPrice,
                                    'kr'
                                )}
                            </b>
                        </Table.Cell>
                        <Table.Cell />
                        <Table.Cell />
                    </Table.Row>

                    {euInstances
                        .sort((i1, i2) => (i1.name > i2.name ? 1 : -1))
                        .map(i => <InstanceRow key={i.id} {...i} />)}

                    <Table.Row>
                        <Table.Cell>
                            <Flag name="au" />
                            <b>
                                {formatPriceSum(
                                    auTotalPrice,
                                    'kr'
                                )}
                            </b>
                        </Table.Cell>
                        <Table.Cell />
                        <Table.Cell />
                    </Table.Row>

                    {auInstances
                        .sort((i1, i2) => (i1.name > i2.name ? 1 : -1))
                        .map(i => <InstanceRow key={i.id} {...i} />)}
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};

export default withRouter(Billing);
