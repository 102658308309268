import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './loader.min.css';

class Loader extends Component {
    render() {
        const { text } = this.props;
        return (
            <div style={{ padding: '0 !important' }} className={`ui active man-loader centered loader${text ? ' text' : ''}`}>{text || ''}</div>
        )
    }
}

Loader.propTypes = {
    text: PropTypes.string
}

export default Loader;