import React, { useEffect, useState } from 'react';
import BottomSheet from 'react-swipeable-bottom-sheet';
import { Button, Form, Header, Input, Table } from 'semantic-ui-react';
import { Divider, LineLoader } from 'trukks-lib';
import { instanceService } from '../../../data/instances/instanceService';

const UpdateSequenceBottomSheetContent = ({
    instanceId,
    instanceName,
    sequenceName,
    sequenceValue,
    onSave,
    loading,
}) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(sequenceValue || 0);
    }, [instanceId, sequenceName, sequenceValue]);

    return (
        <div
            style={{
                minHeight: 400,
                maxWidth: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 40,
            }}>
            <Form>
                <React.Fragment>
                    <Header size="tiny">Update sequence</Header>
                    <Divider
                        style={{
                            marginTop: 0,
                        }}
                    />
                </React.Fragment>

                <Form.Field>
                    <label>Instance</label>
                    {`${instanceName} (${instanceId})`}
                </Form.Field>

                <Form.Field>
                    <label>Sequence</label>
                    {sequenceName}
                </Form.Field>

                <Form.Field>
                    <label>Value</label>
                    <input
                        type="number"
                        onChange={e => setValue(e.target.value)}
                        value={value}
                    />
                </Form.Field>

                <Form.Field>
                    <Button
                        fluid
                        color="instagram"
                        loading={loading}
                        onClick={() => onSave(instanceId, sequenceName, value)}>
                        Save
                    </Button>
                </Form.Field>
            </Form>
        </div>
    );
};

const SequencesForm = ({ id, displayName }) => {
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(null);
    const [sequences, setSequences] = useState([]);

    useEffect(() => {
        const fetchSequences = async () => {
            try {
                setLoading(true);
                const sequences = await instanceService.fetchSequences(id);

                const mapped = Object.keys(sequences)
                    .sort()
                    .filter(seq => seq !== 'instance_id')
                    .map(seq => ({
                        sequenceName: seq,
                        sequenceValue: sequences[seq],
                    }));

                setSequences(mapped);
            } catch (err) {
                console.error(err);
                alert('Failed to fetch sequences');
            } finally {
                setLoading(false);
            }
        };

        fetchSequences();
    }, []);

    const handleUpdate = async (instanceId, sequenceName, sequenceValue) => {
        try {
            setLoading(true);

            await instanceService.updateSequence(
                instanceId,
                sequenceName,
                sequenceValue
            );

            setSequences(seq => {
                const idx = seq.findIndex(s => s.sequenceName === sequenceName);

                seq[idx] = {
                    ...seq[idx],
                    sequenceValue,
                };

                return seq;
            });

            setUpdating(null);
        } catch (err) {
            console.error(err);
            alert(
                `Failed to update instance sequence ${sequenceName} to ${sequenceValue} for ${instanceId}`
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div style={{ marginBottom: 10 }}>
                <Input
                    autoFocus
                    icon="search"
                    iconPosition="left"
                    placeholder="Search..."
                    value={search}
                    onChange={(e, { value }) => setSearch(value)}
                />
            </div>

            <BottomSheet open={!!updating} onChange={() => setUpdating(null)}>
                <UpdateSequenceBottomSheetContent
                    instanceId={id}
                    instanceName={displayName}
                    loading={loading}
                    sequenceName={updating && updating.sequenceName}
                    sequenceValue={updating && updating.sequenceValue}
                    onSave={handleUpdate}
                />
            </BottomSheet>

            <Table className="man-form-table" celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {loading && !sequences && (
                        <Table.Row>
                            <Table.Cell>
                                <LineLoader />
                            </Table.Cell>
                            <Table.Cell>
                                <LineLoader />
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {sequences
                        .filter(seq =>
                            search
                                ? seq.sequenceName.indexOf(
                                      search.toLowerCase()
                                  ) > -1
                                : true
                        )
                        .map(({ sequenceName, sequenceValue }) => (
                            <Table.Row key={sequenceName}>
                                <Table.Cell>{sequenceName}</Table.Cell>
                                <Table.Cell>
                                    {sequenceValue}
                                    <div>
                                        <a
                                            onClick={() =>
                                                setUpdating({
                                                    sequenceName,
                                                    sequenceValue,
                                                })
                                            }>
                                            EDIT
                                        </a>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};

export default SequencesForm;
