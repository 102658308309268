import { combineReducers } from 'redux';
import { instances } from '../instances/instanceReducer';
import { modules } from '../modules/moduleReducer';

const appReducer = combineReducers({
    instances,
    modules
});

const rootReducer = (state, action) => {

    return appReducer(state, action);
}

export default rootReducer;