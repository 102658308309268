import { moduleService } from './moduleService';

export const FETCH_MODULES = 'modules/fetch';
export const FETCH_MODULES_FULFILLED = 'modules/fetch/fulfilled';
export const FETCH_MODULES_REJECTED = 'modules/fetch/rejected';

const requestModules = () => ({ type: FETCH_MODULES });
const requestModulesFulfilled = modules => ({ type: FETCH_MODULES_FULFILLED, modules });
const requestModulesRejected = err => ({ type: FETCH_MODULES_REJECTED, err });

export const fetchModules = () => async dispatch => {

    dispatch(requestModules());

    try {
        const modules = await moduleService.fetch();
        dispatch(requestModulesFulfilled(modules));
    } catch (err) {
        alert(err);
        dispatch(requestModulesRejected(err));
    }
};