import React, { useState, useEffect } from 'react';
import { integrationsService } from '../../../../data/integrations/integrationsService';
import moment from 'moment';
import { DashboardCard } from '../DashboardCard';

const IntegrationsCard = () => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState({});

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                setLoading(true);

                const res = await integrationsService.fetchErrors('service.error', 11)

                setResponse(res);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        fetchLogs();
    }, []);

    const { Items } = response;

    const today = moment().startOf('day').format('YYYY-MM-DD');
    const threeDaysBack = moment().subtract(3, 'days').startOf('day').format('YYYY-MM-DD');

    const dailyErrors = (Items || []).filter(({ Timestamp }) => Timestamp > today);
    const lastThreeDaysErrors = (Items || []).filter(({ Timestamp }) => Timestamp > threeDaysBack);


    return (
        <DashboardCard to="/integrations"
            text="App events"
            isLoading={loading}>
            <div style={{
                marginBottom: 20
            }}>
                <div style={{ marginBottom: 10 }}>
                    <b>today</b>
                    <div
                        style={{
                            color: `${dailyErrors.length > 10 ? 'var(--color-red)' : dailyErrors.length > 0 ? 'var(--color-yellow)' : 'var(--color-green)'}`
                        }}>
                        <b>
                            {dailyErrors.length > 10 ? '10+' : dailyErrors.length}
                        </b>
                    </div>
                </div>
                <div className="ui divider" />
                <div>
                    <b>last 3 days</b>
                    <div
                        style={{
                            color: `${lastThreeDaysErrors.length > 10 ? 'var(--color-red)' : lastThreeDaysErrors.length > 0 ? 'var(--color-yellow)' : 'var(--color-green)'}`
                        }}>
                        <b>
                            {lastThreeDaysErrors.length > 10 ? '10+' : lastThreeDaysErrors.length}
                        </b>
                    </div>

                </div>
            </div>
        </DashboardCard >

    )
}

export default IntegrationsCard;