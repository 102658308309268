import React, { useEffect, useState } from 'react';
import { DashboardCard } from '../DashboardCard';
import { integrationsService } from '../../../../data/integrations/integrationsService';


const ErpQueueCard = () => {

    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({});

    useEffect(() => {
        const fetchCount = async () => {
            try {
                setLoading(true);

                const res = await integrationsService.fetchErpQueueCount();

                setResponse(res);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        fetchCount();
    }, []);

    return (
        <DashboardCard
            text="ERP Queue"
            isLoading={loading}
            to="/erp">
            <div>
                {response && response.count != null &&
                    <h1 style={{
                        color: response.count > 0 ? 'var(--color-red)' : 'var(--color-green)'
                    }}>
                        {response.count}
                    </h1>
                }
            </div>
        </DashboardCard>
    )
}

export default ErpQueueCard;