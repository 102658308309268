import { API_ROOT, API_ROOT_AU, ENVIRONMENTS, get, patch, post, put } from '../../utils/http/HttpClient';

export const instanceService = {
    fetch: async () => {
        const { instances } = await get('/instance');
        return instances;
    },
    fetchDetails: async id => {
        const { instance } = await get(`/instance/${id}`);
        return instance;
    },
    fetchPermissions: async () => {
        const { permissions } = await get('/permission');
        return permissions;
    },
    updateStatus: async (instanceId, status) => {
        await put(`/instance/${instanceId}/status`, { status });
    },
    updateConf: async (instanceId, configuration) => {
        await put(`/instance/${instanceId}/conf`, { configuration });
    },
    updateBoConf: async (instanceId, configuration) => {
        await put(`/instance/${instanceId}/boconf`, { configuration });
    },
    updateWoConf: async (instanceId, configuration) => {
        await put(`/instance/${instanceId}/woconf`, { configuration });
    },
    fetchUserStats: async id => {
        return await get(`/stats/users?instanceId=${id}`);
    },
    patchUser: async (instanceId, user) => {
        await patch(`/instance/${instanceId}/user`, user);
    },
    fetchUserOpenSessions: async (instanceId, userId) => {
        return await get(`/instance/${instanceId}/user/${userId}/session`);
    },
    changeUserPassword: async (instanceId, userId, password) => {
        await post(`/instance/${instanceId}/user/${userId}/password`, {
            password,
        });
    },
    fetchBilling: async () => {
        const agreementInstances = await get(`/instance/billing/agreement`, ENVIRONMENTS.EU);

        const instancesEU = await get(`/instance/billing/instance`, ENVIRONMENTS.EU);
        const instancesAU = await get(`/instance/billing/instance`, ENVIRONMENTS.AU);

        const instances = [
            ...instancesEU.instances.map(i => ({ ...i, environment: ENVIRONMENTS.EU })),
            ...instancesAU.instances.map(i => ({ ...i, environment: ENVIRONMENTS.AU }))
        ]

        const billingInstances = (agreementInstances.instances || [])
            .map(agreementData => {
                const instanceData = instances.find(i => agreementData.id === i.id);

                if (!instanceData) {
                    return;
                }

                return {
                    ...agreementData,
                    name: instanceData.name,
                    activeUsers: instanceData.activeUsers,
                    crmActiveUsers: instanceData.crmActiveUsers,
                    environment: instanceData.environment
                }
            })
            .filter(v => v);

            console.log(billingInstances)
        return {
            instances: billingInstances
        }
    },
    addAppModule: async (instanceId, appModuleId) => {
        return await post(`/instance/${instanceId}/appmodule/add`, {
            instanceId,
            appModuleId,
        });
    },
    removeAppModule: async (instanceId, appModuleId) => {
        return await post(`/instance/${instanceId}/appmodule/remove`, {
            instanceId,
            appModuleId,
        });
    },
    updateAppModuleConfiguration: async (
        instanceId,
        appModuleId,
        configuration
    ) => {
        return await put(`/instance/${instanceId}/appmodule`, {
            instanceId,
            appModuleId,
            configuration,
        });
    },
    updateAppModuleInternalConfiguration: async (
        instanceId,
        appModuleId,
        configuration
    ) => {
        return await put(`/instance/${instanceId}/appmodule/internal`, {
            instanceId,
            appModuleId,
            configuration,
        });
    },
    updateAppModuleDynamicConfiguration: async (
        instanceId,
        appModuleId,
        configuration
    ) => {
        return await put(`/instance/${instanceId}/appmodule/dynamic`, {
            instanceId,
            appModuleId,
            configuration,
        });
    },
    fetchSequences: async instanceId => {
        return await get(`/instance/${instanceId}/sequences`);
    },
    updateSequence: async (instanceId, sequenceName, sequenceValue) => {
        return await put(`/instance/${instanceId}/sequences`, {
            sequenceName,
            sequenceValue,
        });
    },
};
