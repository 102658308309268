import React, { useState, useEffect } from 'react';
import { instanceService } from '../../../../data/instances/instanceService';
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

function uniqueBy(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

const InstanceStats = ({ id }) => {

    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                setLoading(true);

                const res = await instanceService.fetchUserStats(id);

                setLogs(res);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }

        }

        fetchStats();
    }, []);

    if (loading) {
        return <div>Loading stats...</div>
    }

    const seen = new Set();

    const data = logs.map(l => {
        l.key = l.timestamp.substring(0, l.timestamp.indexOf('T'));

        if (seen.has(l.key)) {
            return null;
        }

        seen.add(l.key);

        return l;
    })
        .filter(v => v);

    return (
        <div style={{
            width: '100%',
            height: 500
        }}>
            <ResponsiveContainer>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        // top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 3" />
                    <XAxis dataKey="key" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="active" stroke="#a3c293" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="registered" stroke="#c9ba9b" />
                    <Line type="monotone" dataKey="inactive" stroke="#9f3a38" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
};

export default InstanceStats;