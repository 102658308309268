import React, { useEffect, useState } from 'react';
import { Form, Dropdown, Input, Button, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchInstances } from '../../../data/instances/instanceActions';
import { integrationsService } from '../../../data/integrations/integrationsService';

const ResponseTable = ({ response, onIgnore, onRetry }) => {
    if (!response) {
        return null;
    }

    const { items } = response;

    console.log(items);

    return (
        <Table className="man-form-table" celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={8}>
                        {`${items.length} items`}
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>InstanceId</Table.HeaderCell>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Address</Table.HeaderCell>
                    <Table.HeaderCell>Retry count</Table.HeaderCell>
                    <Table.HeaderCell>Update ts</Table.HeaderCell>
                    <Table.HeaderCell>Create ts</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map(item => (
                    <Table.Row key={`${item.id}-${item.requestAddress}`}>
                        <Table.Cell>
                            {item.instanceId}
                        </Table.Cell>
                        <Table.Cell>
                            {item.id}
                        </Table.Cell>
                        <Table.Cell>
                            {item.status}
                        </Table.Cell>
                        <Table.Cell>
                            {item.requestAddress}
                        </Table.Cell>
                        <Table.Cell>
                            {item.retryCount}
                        </Table.Cell>
                        <Table.Cell>
                            {item.updateTs}
                        </Table.Cell>
                        <Table.Cell>
                            {item.createTs}
                        </Table.Cell>
                        <Table.Cell>
                            <Button fluid 
                                color="orange"
                                size="mini"
                                style={{
                                    margin: 2
                                }}
                                onClick={() => onIgnore(item)}>
                                Ignore
                                </Button>
                            <Button fluid 
                                color="green"
                                size="mini"
                                style={{
                                    margin: 2
                                }}
                                onClick={() => onRetry(item)}>
                                Retry
                                </Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}

const ErpQueue = ({ instances, isLoadingInstances, fetchInstances }) => {

    const [selectedInstance, setSelectedInstance] = useState('');
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        instances.length === 0 && fetchInstances();
    }, []);

    const fetchErpQueue = async () => {

        try {
            setLoading(true);

            const res = await integrationsService.fetchErpQueue(selectedInstance, limit);

            setResponse(res);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const handleRetry = async item => {
        const res = window.confirm('Are you sure to retry?');

        if (!res) {
            return;
        }

        try {
            setLoading(true);

            await integrationsService.retryErpQueueItem(item);

            await fetchErpQueue();
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const handleIgnore = async item => {
        const res = window.confirm('Are you sure to ignore?');

        if (!res) {
            return;
        }

        try {
            setLoading(true);

            await integrationsService.ignoreErpQueueItem(item);

            await fetchErpQueue();
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <Dropdown
                            clearable
                            search selection
                            placeholder="Filter by instance"
                            value={selectedInstance}
                            loading={isLoadingInstances}
                            options={instances.map(i => ({ key: i.id, value: i.id, text: i.displayName }))}
                            onChange={(e, { value }) => setSelectedInstance(value)} />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            type="number"
                            placeholder="Limit (10)"
                            value={limit}
                            onChange={(e, { value }) => setLimit(value)} />
                    </Form.Field>
                    <Form.Field>
                        <Button fluid
                            color="instagram"
                            loading={loading}
                            onClick={fetchErpQueue}>
                            Search
                        </Button>
                    </Form.Field>
                    <Form.Field></Form.Field>
                </Form.Group>
            </Form>

            <div>
                <ResponseTable response={response}
                    onIgnore={handleIgnore}
                    onRetry={handleRetry}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    instances: state.instances.instances,
    isLoadingInstances: state.instances.isLoading
})

const mapDispatchToProps = {
    fetchInstances
}

export default connect(mapStateToProps, mapDispatchToProps)(ErpQueue);