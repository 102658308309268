import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dropdown, Form, Input, Table } from 'semantic-ui-react';
import { fetchInstances } from '../../../data/instances/instanceActions';
import { moduleService } from '../../../data/modules/moduleService';

const download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

const ENABLED_SUPPLIERS = [
    {
        id: 'copiax',
        displayName: 'Copiax',
    },
    {
        id: 'elektroskandia',
        displayName: 'Elektroskandia',
    },
    {
        id: 'solar',
        displayName: 'Solar',
    },
    {
        id: 'ahlsell',
        displayName: 'Ahlsell',
    },
    {
        id: 'axsnordic',
        displayName: 'AXS Nordic',
    },
];

const Orders = ({ orders }) => {
    return (
        <Table className="man-form-table" celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={5}>
                        {`Found ${orders.length} orders`}
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>InstanceId/SupplierId</Table.HeaderCell>
                    <Table.HeaderCell>Work Order</Table.HeaderCell>
                    <Table.HeaderCell>File</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {orders.map(item => {
                    const fileName = item.fileName.split('/').pop();

                    return (
                        <Table.Row
                            key={`${item.supplierOrderId}-${item.createTs}`}>
                            <Table.Cell>
                                {item.supplierOrderId}
                                <div className="trks-secondary-text">
                                    {item.createTs}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                {item.instanceId}/{item.supplierName}
                            </Table.Cell>
                            <Table.Cell>{item.workOrderTitle}</Table.Cell>
                            <Table.Cell>
                                <a onClick={() => download(fileName, item.fileContent)}>{fileName}</a>
                            </Table.Cell>
                            <Table.Cell>
                                <div>
                                    <a
                                        onClick={() =>
                                            alert(
                                                JSON.stringify(
                                                    JSON.parse(item.data),
                                                    null,
                                                    2
                                                )
                                            )
                                        }>
                                        Show parsed data
                                    </a>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

const SupplierOrders = ({ instances, isLoadingInstances, fetchInstances }) => {
    const [instanceId, setInstanceId] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const [supplierOrderId, setSupplierOrderId] = useState('');

    const [suppliers, setSuppliers] = useState(ENABLED_SUPPLIERS);

    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        instances.length === 0 && fetchInstances();
    }, []);

    const fetchOrder = async () => {
        if (!supplierOrderId) {
            return;
        }

        try {
            setLoading(true);

            const res = await moduleService.fetchSupplierOrder(
                supplierOrderId,
                instanceId,
                supplierId
            );

            setOrders(res);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="man-bo-instances-list">
            <div style={{ marginBottom: 20 }}>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <Dropdown
                                clearable
                                search
                                selection
                                placeholder="Instance"
                                value={instanceId}
                                loading={isLoadingInstances}
                                options={instances.map(i => ({
                                    key: i.id,
                                    value: i.id,
                                    text: i.displayName,
                                }))}
                                onChange={(e, { value }) =>
                                    setInstanceId(value)
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                allowAdditions
                                clearable
                                search
                                selection
                                placeholder="Supplier"
                                value={supplierId}
                                options={suppliers.map(i => ({
                                    key: i.id,
                                    value: i.id,
                                    text: i.displayName,
                                }))}
                                onChange={(e, { value }) =>
                                    setSupplierId(value)
                                }
                                onAddItem={(e, { value }) =>
                                    setSuppliers([
                                        ...suppliers,
                                        { id: value, displayName: value },
                                    ])
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                required
                                placeholder="Order ID *"
                                value={supplierOrderId}
                                onChange={(e, { value }) =>
                                    setSupplierOrderId(value)
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button
                                fluid
                                color="instagram"
                                loading={loading}
                                onClick={fetchOrder}>
                                Search
                            </Button>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </div>
            <div>
                <Orders orders={orders} />
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    instances: state.instances.instances,
    isLoadingInstances: state.instances.isLoading,
});

const mapDispatchToProps = {
    fetchInstances,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SupplierOrders)
);
