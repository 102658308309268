import React, { useState, useEffect } from 'react';
import { Dropdown, Form, Input, Button, Table, Label } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchInstances } from '../../../data/instances/instanceActions';
import { integrationsService } from '../../../data/integrations/integrationsService';

const Logs = ({ response }) => {
    if (!response) {
        return null;
    }

    const { Items, Count, ScannedCount } = response;

    return (
        <Table className="man-form-table" celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={5}>
                        {`${Count} items / ${ScannedCount} scanned`}
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>Timestamp</Table.HeaderCell>
                    <Table.HeaderCell>InstanceId/ServiceId</Table.HeaderCell>
                    <Table.HeaderCell>Message</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Items && Items
                    .map(item => (
                        <Table.Row key={`${item.Id}-${item.Timestamp}`}>
                            <Table.Cell>
                                {item.Id}
                            </Table.Cell>
                            <Table.Cell>
                                {item.Timestamp}
                            </Table.Cell>
                            <Table.Cell>
                                {item.InstanceId}/{item.ServiceId}
                            </Table.Cell>
                            <Table.Cell>
                                {item.Message}
                            </Table.Cell>
                            <Table.Cell>
                                <div>
                                    <a onClick={() => alert(item.Ref)}>
                                        Ref
                                    </a>
                                </div>
                                <div>
                                    <a onClick={() => alert(item.ErrorRef)}>
                                        ErrorRef
                                </a>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    ))}
            </Table.Body>
        </Table>
    )
}

const Integrations = ({ instances, isLoadingInstances, fetchInstances }) => {

    const [selectedInstance, setSelectedInstance] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        instances.length === 0 && fetchInstances();
    }, []);

    const fetchLogs = async () => {
        const topic = `service.error${selectedInstance ? `::${selectedInstance}` : ''}${selectedInstance && serviceId ? `::${serviceId}` : ''}`;

        try {
            setLoading(true);

            const res = await integrationsService.fetchErrors(topic, limit)

            setResponse(res);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="man-bo-instances-list">
            <div style={{ marginBottom: 20 }}>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Input
                                value={'service.error'} />
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                clearable
                                search selection
                                placeholder="Filter by instance"
                                value={selectedInstance}
                                loading={isLoadingInstances}
                                options={instances.map(i => ({ key: i.id, value: i.id, text: i.displayName }))}
                                onChange={(e, { value }) => setSelectedInstance(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                placeholder="Service ID"
                                value={serviceId}
                                onChange={(e, { value }) => setServiceId(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                type="number"
                                placeholder="Limit (10)"
                                value={limit}
                                onChange={(e, { value }) => setLimit(value)} />
                        </Form.Field>
                        <Form.Field>
                            <Button fluid
                                color="instagram"
                                loading={loading}
                                onClick={fetchLogs}>
                                Search
                            </Button>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </div>
            <div>
                <Logs response={response} />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    instances: state.instances.instances,
    isLoadingInstances: state.instances.isLoading
})

const mapDispatchToProps = {
    fetchInstances
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Integrations));