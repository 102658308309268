import React from 'react';
import { Table } from 'semantic-ui-react';

const AclForm = ({ acls }) => {


    return (
        <React.Fragment>

            <Table className="man-form-table" celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Configuration</Table.HeaderCell>
                        <Table.HeaderCell>Permissions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {acls.sort((a, b) => a.displayName > b.displayName ? 1 : -1).map(a => (
                        <Table.Row key={a.id}>
                            <Table.Cell>
                                {a.displayName}
                            </Table.Cell>
                            <Table.Cell>
                                {a.description}
                            </Table.Cell>
                            <Table.Cell>
                                Work orders limit: {JSON.parse(a.configuration).limit}
                            </Table.Cell>
                            <Table.Cell>
                                {a.permissions.length}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </React.Fragment>
    )

}

export default AclForm;