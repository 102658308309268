import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BottomSheet from 'react-swipeable-bottom-sheet';
import {
    Button,
    Dropdown,
    Form,
    Header,
    Input,
    Table,
} from 'semantic-ui-react';
import { Divider } from 'trukks-lib';
import { instanceService } from '../../../data/instances/instanceService';
import { fetchModules } from '../../../data/modules/moduleActions';
import JsonView from '../../common/json-view';

const isJsonString = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const formatJsonString = str => {
    let data = null;

    if (str) {
        try {
            data = JSON.parse(str);
        } catch {
            
        }
    }

    return data ? JSON.stringify(data, null, 2) : str;
}

const getMapsConfiguration = configuration => {
    const conf = JSON.parse(configuration);

    return {
        lat: conf.home ? conf.home.lat : '-',
        lng: conf.home ? conf.home.lng : '-',
    };
};

const getAnlaggaryntigConfiguration = configuration => {
    const conf = JSON.parse(configuration);

    return {
        templateId: conf.templateId || '',
    };
};

const getErpIntegrationConfiguration = configuration => {
    const conf = JSON.parse(configuration);

    return {
        erpId: conf.erpId || '',
    };
};

const AddAppModuleBottomSheetContent = ({
    instanceId,
    instanceName,
    onSave,
    loading,
    appModules,
}) => {
    const [selectedModule, setSelectedModule] = useState('');
    const dispatch = useDispatch();
    const { isLoadingModules, modules } = useSelector(state => ({
        modules: state.modules.modules,
        isLoadingModules: state.modules.isLoading,
    }));

    useEffect(() => {
        modules.length === 0 && dispatch(fetchModules());
    }, []);

    const enabledModules = appModules.map(m => m.id);

    const filteredModules = modules.filter(
        m => enabledModules.indexOf(m.id) === -1
    );

    return (
        <div
            style={{
                minHeight: 400,
                maxWidth: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 40,
            }}>
            <Form>
                <React.Fragment>
                    <Header size="tiny">{`${instanceName} (${instanceId})`}</Header>

                    <Divider
                        style={{
                            marginTop: 0,
                        }}
                    />
                </React.Fragment>

                <Form.Field>
                    <label>Module</label>
                    <Dropdown
                        search
                        selection
                        value={selectedModule}
                        onChange={(e, { value }) => setSelectedModule(value)}
                        options={filteredModules.map(m => ({
                            key: m.id,
                            value: m.id,
                            text: m.id,
                        }))}
                    />
                </Form.Field>

                <Form.Field>
                    <Button
                        fluid
                        color="instagram"
                        loading={loading}
                        onClick={() => onSave(instanceId, selectedModule)}>
                        Save
                    </Button>
                </Form.Field>
            </Form>
        </div>
    );
};

const RemoveAppModuleBottomSheetContent = ({
    instanceId,
    instanceName,
    appModuleId,
    onSave,
    loading,
}) => {
    return (
        <div
            style={{
                minHeight: 400,
                maxWidth: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 40,
            }}>
            <Form>
                <React.Fragment>
                    <Header size="tiny">{`Are you sure to remove app module ${appModuleId} for ${instanceName} (${instanceId}). All configuration will be lost.`}</Header>

                    <Divider
                        style={{
                            marginTop: 0,
                        }}
                    />
                </React.Fragment>

                <Form.Field>
                    <Button
                        fluid
                        color="red"
                        inverted
                        loading={loading}
                        onClick={() => onSave(instanceId, appModuleId)}>
                        Remove
                    </Button>
                </Form.Field>
            </Form>
        </div>
    );
};

const UpdateAppModuleBottomSheetContent = ({
    instanceId,
    instanceName,
    appModuleId,
    configuration,
    onSave,
    loading,
    configurationType,
}) => {
    const [conf, setConf] = useState('');

    useEffect(() => {
        setConf(configuration || '');
    }, [appModuleId, instanceId, configuration]);

    const ok = isJsonString(conf);

    return (
        <div
            style={{
                minHeight: 400,
                maxWidth: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 40,
            }}>
            <Form>
                <React.Fragment>
                    <Header size="tiny">Update configuration</Header>
                    <Divider
                        style={{
                            marginTop: 0,
                        }}
                    />
                </React.Fragment>

                <Form.Field>
                    <label>Instance</label>
                    {`${instanceName} (${instanceId})`}
                </Form.Field>

                <Form.Field>
                    <label>App module</label>
                    {appModuleId}
                </Form.Field>

                <Form.Field>
                    <label>Configuration ({configurationType})</label>
                    <textarea
                        onChange={e => setConf(e.target.value)}
                        value={formatJsonString(conf)}
                    />
                </Form.Field>

                <Form.Field>
                    {!ok && (
                        <div className="trks-secondary-text red text ui">
                            Invalid JSON
                        </div>
                    )}
                    <Button
                        fluid
                        color="instagram"
                        loading={loading}
                        onClick={() =>
                            ok &&
                            onSave(instanceId, appModuleId, JSON.parse(conf))
                        }>
                        Save
                    </Button>
                </Form.Field>
            </Form>
        </div>
    );
};

const AppModulesForm = ({
    onAppModulesUpdated,
    appModules,
    id,
    displayName,
}) => {
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [adding, setAdding] = useState(false);
    const [removing, setRemoving] = useState(null);
    const [updating, setUpdating] = useState(null);

    const modules = search
        ? appModules.filter(
              appModule =>
                  appModule.id.toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        : appModules;

    const handleAdd = async (instanceId, appModuleId) => {
        try {
            setLoading(true);

            await instanceService.addAppModule(instanceId, appModuleId);

            setAdding(false);
            onAppModulesUpdated();
        } catch (err) {
            console.error(err);
            alert(`Failed to add appModule ${appModuleId} to ${instanceId}`);
        } finally {
            setLoading(false);
        }
    };

    const handleRemove = async (instanceId, appModuleId) => {
        try {
            setLoading(true);

            await instanceService.removeAppModule(instanceId, appModuleId);

            setRemoving(null);
            onAppModulesUpdated();
        } catch (err) {
            console.error(err);
            alert(
                `Failed to remove appModule ${appModuleId} from ${instanceId}`
            );
        } finally {
            setLoading(false);
        }
    };

        const handleUpdate = async (instanceId, appModuleId, configuration) => {
            try {
                setLoading(true);

                await instanceService.updateAppModuleConfiguration(
                    instanceId,
                    appModuleId,
                    configuration
                );

                setUpdating(null);
                onAppModulesUpdated();
            } catch (err) {
                console.error(err);
                alert(
                    `Failed to update appModule ${appModuleId} for ${instanceId}`
                );
            } finally {
                setLoading(false);
            }
        };

    const handleUpdateInternal = async (
        instanceId,
        appModuleId,
        configuration
    ) => {
        try {
            setLoading(true);

            await instanceService.updateAppModuleInternalConfiguration(
                instanceId,
                appModuleId,
                configuration
            );

            setUpdating(null);
            onAppModulesUpdated();
        } catch (err) {
            console.error(err);
            alert(
                `Failed to update appModule internal ${appModuleId} for ${instanceId}`
            );
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateDynamic = async (
        instanceId,
        appModuleId,
        configuration
    ) => {
        try {
            setLoading(true);

            await instanceService.updateAppModuleDynamicConfiguration(
                instanceId,
                appModuleId,
                configuration
            );

            setUpdating(null);
            onAppModulesUpdated();
        } catch (err) {
            console.error(err);
            alert(
                `Failed to update appModule ${appModuleId} for ${instanceId}`
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div style={{ marginBottom: 10 }}>
                <Input
                    autoFocus
                    icon="search"
                    iconPosition="left"
                    placeholder="Search..."
                    value={search}
                    onChange={(e, { value }) => setSearch(value)}
                />

                <Button
                    onClick={() => setAdding(true)}
                    color="instagram"
                    style={{
                        float: 'right',
                    }}>
                    Add module
                </Button>
            </div>

            <BottomSheet open={adding} onChange={() => setAdding(false)}>
                <AddAppModuleBottomSheetContent
                    instanceId={id}
                    instanceName={displayName}
                    loading={loading}
                    appModules={appModules}
                    onSave={handleAdd}
                />
            </BottomSheet>

            <BottomSheet open={!!removing} onChange={() => setRemoving(null)}>
                <RemoveAppModuleBottomSheetContent
                    instanceId={id}
                    instanceName={displayName}
                    loading={loading}
                    appModuleId={removing}
                    onSave={handleRemove}
                />
            </BottomSheet>

            <BottomSheet open={!!updating} onChange={() => setUpdating(null)}>
                <UpdateAppModuleBottomSheetContent
                    instanceId={id}
                    instanceName={displayName}
                    loading={loading}
                    appModuleId={updating && updating.appModuleId}
                    configuration={updating && updating.appModuleConfiguration}
                    configurationType={updating && updating.configurationType}
                    onSave={updating && updating.handleUpdateFn}
                />
            </BottomSheet>

            <Table className="man-form-table" celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>Configuration</Table.HeaderCell>
                        <Table.HeaderCell>Internal</Table.HeaderCell>
                        <Table.HeaderCell>Dynamic</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {modules.map(
                        ({
                            id,
                            configuration,
                            internalConfiguration,
                            dynamicConfiguration,
                        }) => (
                            <Table.Row key={id}>
                                <Table.Cell>
                                    {id}
                                    <div>
                                        <a onClick={() => setRemoving(id)}>
                                            REMOVE
                                        </a>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <div>
                                        <a
                                            onClick={() =>
                                                setUpdating({
                                                    appModuleId: id,
                                                    appModuleConfiguration:
                                                        configuration,
                                                    handleUpdateFn:
                                                        handleUpdate,
                                                    configurationType: 'custom'
                                                })
                                            }>
                                            EDIT
                                        </a>
                                    </div>
                                    <JsonView jsonString={configuration} />
                                </Table.Cell>
                                <Table.Cell>
                                    <div>
                                        <a
                                            onClick={() =>
                                                setUpdating({
                                                    appModuleId: id,
                                                    appModuleConfiguration:
                                                        internalConfiguration,
                                                    handleUpdateFn:
                                                        handleUpdateInternal,
                                                    configurationType: 'internal'
                                                })
                                            }>
                                            EDIT
                                        </a>
                                    </div>
                                    <JsonView
                                        jsonString={internalConfiguration}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <div>
                                        <a
                                            onClick={() =>
                                                setUpdating({
                                                    appModuleId: id,
                                                    appModuleConfiguration:
                                                        dynamicConfiguration,
                                                    handleUpdateFn:
                                                        handleUpdateDynamic,
                                                    configurationType: 'dynamic'
                                                })
                                            }>
                                            EDIT
                                        </a>
                                    </div>
                                    <JsonView
                                        jsonString={dynamicConfiguration}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )
                    )}
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};

export default AppModulesForm;
