import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Image, Input, Label } from 'semantic-ui-react';
import { fetchInstances } from '../../../data/instances/instanceActions';

const InstanceCard = ({
    id,
    configuration,
    displayName,
    onClick,
    status,
    userCount,
}) => (
    <Card>
        <Card.Content>
            <Label
                size="tiny"
                color={status === 'STATUS_ACTIVE' ? 'green' : 'orange'}>
                {status.split('_')[1]}
            </Label>
            <div className="man-card-image-wrapper">
                <Image src={configuration.logoUrl} />
            </div>
            <Card.Header
                style={{
                    fontSize: '1em',
                }}>
                {displayName}
            </Card.Header>
            <Card.Meta
                style={{
                    fontSize: '0.9em',
                }}>
                <span className="date">{`${userCount} users | ${id}`}</span>
            </Card.Meta>
        </Card.Content>
        <Card.Content extra>
            <a className="man-details-link" onClick={onClick}>
                View/Edit
            </a>
        </Card.Content>
    </Card>
);

const Instances = ({ history, instances, isLoading, fetchInstances }) => {
    const [search, setSearch] = useState('');

    const handleEdit = id => history.push(`/instances/${id}`);

    useEffect(() => {
        fetchInstances();
    }, []);

    let filteredInstances = [...instances];

    if (search) {
        filteredInstances = filteredInstances.filter(
            i =>
                i.displayName.toLowerCase().indexOf(search.toLowerCase()) >
                    -1 || i.id.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="man-bo-instances-list">
            <div style={{ marginBottom: 20 }}>
                <Input
                    icon="search"
                    autoFocus
                    iconPosition="left"
                    placeholder="Search..."
                    value={search}
                    onChange={(e, { value }) => setSearch(value)}>
                </Input>
            </div>

            <Card.Group itemsPerRow={4} doubling>
                {filteredInstances
                    .sort((i1, i2) =>
                        i1.displayName > i2.displayName ? 1 : -1
                    )
                    .map(instance => (
                        <InstanceCard
                            key={instance.id}
                            {...instance}
                            onClick={() => handleEdit(instance.id)}
                        />
                    ))}
            </Card.Group>
        </div>
    );
};

const mapStateToProps = state => ({
    instances: state.instances.instances,
    isLoading: state.instances.isLoading,
});

const mapDispatchToProps = {
    fetchInstances,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Instances)
);
