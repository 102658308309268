import { get } from '../../utils/http/HttpClient';

export const statsService = {
    fetchAgreementInvoicing: async () => {
        const { yearly, monthly } = await get('/stats/invoicing/agreements');

        return {
            yearly,
            monthly,
        };
    },
    fetchOtherInvoicing: async () => {
        const { yearly, monthly } = await get('/stats/invoicing/other');

        return {
            yearly,
            monthly,
        };
    },
    fetchInstances: async () => {
        const { yearly, monthly, yearlyChurn, monthlyChurn } = await get(
            '/stats/instances'
        );

        return {
            yearly,
            monthly,
            yearlyChurn,
            monthlyChurn,
        };
    },
};
