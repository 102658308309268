import { instanceService } from './instanceService';

export const FETCH_INSTANCES = 'instances/fetch';
export const FETCH_INSTANCES_FULFILLED = 'instances/fetch/fulfilled';
export const FETCH_INSTANCES_REJECTED = 'instances/fetch/rejected';

const requestInstances = () => ({ type: FETCH_INSTANCES });
const requestInstancesFulfilled = instances => ({ type: FETCH_INSTANCES_FULFILLED, instances });
const requestInstancesRejected = err => ({ type: FETCH_INSTANCES_REJECTED, err });

export const fetchInstances = () => async dispatch => {

    dispatch(requestInstances());

    try {
        const instances = await instanceService.fetch();
        dispatch(requestInstancesFulfilled(instances));
    } catch (err) {
        alert(err);
        dispatch(requestInstancesRejected(err));
    }
};