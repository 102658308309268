import CacheManager from "../../utils/cache/CacheManager";
import CacheManagerProps from "../../utils/cache/CacheManagerProps";
import { ENVIRONMENTS, post } from "../../utils/http/HttpClient";

export const authService = {
    getGoogleAuthUri: async () => {
        const { redirectUri } = await post(`/auth/google`);

        return redirectUri;
    },
    getEnvironment: async () => {
        const env = await CacheManager.get(CacheManagerProps.ENV);

        return env || ENVIRONMENTS.EU;
    }
}