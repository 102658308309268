import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducer';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loggerMiddleware = createLogger();

const configureStoreForDevelopment = () => {
    const store = createStore(
        rootReducer,
        composeEnhancer(
            applyMiddleware(
                thunkMiddleware,
                loggerMiddleware
            )
        )
    )
    return store;
};

const configureStore = () => {
    const store = createStore(
        rootReducer,
        composeEnhancer(
            applyMiddleware(
                thunkMiddleware
            )
        )
    )
    return store;
};

export const store = process.env.NODE_ENV === 'development' ? configureStoreForDevelopment() : configureStore();