import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonGroup, Dropdown, Flag } from 'semantic-ui-react';
import { authService } from '../../../data/auth/authService';
import CacheManager from '../../../utils/cache/CacheManager';
import CacheManagerProps from '../../../utils/cache/CacheManagerProps';
import { ENVIRONMENTS } from '../../../utils/http/HttpClient';

const Topbar = ({ email, onLogout }) => {
    const [environment, setEnvironment] = useState(null);

    useEffect(() => {
        const fetchEnvironment = async () => {
            try {
                const env = await authService.getEnvironment();
                setEnvironment(env);
            } catch {
                alert('Unable to fetch environment details');
            }
        }

        fetchEnvironment();
    }, []);

    const updateEnvironment = async env => {
        setEnvironment(env);
        await CacheManager.put(CacheManagerProps.ENV, env);

        document.location.reload();
    }

    return (
        <div className="man-topbar">
            <div></div>
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Dropdown text={email} pointing direction="left">
                    <Dropdown.Menu>
                        <Dropdown.Item as={NavLink} exact to="/" text="Dashboard" />
                        <Dropdown.Item as={NavLink} to="/instances" text="Instances" />
                        <Dropdown.Item as={NavLink} to="/billing" text="Billing" />
                        <Dropdown.Item as={NavLink} to="/modules" text="Modules" />
                        <Dropdown.Item as={NavLink} to="/supplier-orders" text="Supplier orders" />

                        <Dropdown.Divider />
                        <Dropdown.Item text="Log out" onClick={onLogout} />
                    </Dropdown.Menu>
                </Dropdown>

                <ButtonGroup size="mini">
                    <Button style={{
                        opacity: environment === ENVIRONMENTS.EU ? 1 : 0.3
                    }}
                        onClick={() => updateEnvironment('eu')}>
                        <Flag name="eu" /> EU
                    </Button>
                    {process.env.REACT_APP_API_ROOT_AU &&
                        <Button style={{
                            opacity: environment === ENVIRONMENTS.AU ? 1 : 0.3
                        }}
                            onClick={() => updateEnvironment('au')}>
                            <Flag name="au" />AU
                        </Button>
                    }
                </ButtonGroup>
            </div>
        </div>
    );
};

export default Topbar;
