import React from 'react';
import { withRouter } from 'react-router-dom';
import './Dashboard.css';
import ErpQueueCard from './erp-queue-card';
import { HealthCheckCard } from './health';
import { InstanceCard } from './instance-card';
import IntegrationsCard from './integrations-card';
import InvoicingCard from './invoicing-card/InvoicingCard';

const FakeCard = () => (
    <div className="man-bo-dashboard-item man-bo-dashboard-item-fake"></div>
);

const Dashboard = () => {
    
    return (
        <React.Fragment>
            <div className="man-bo-dashboard-items">
                <InstanceCard />

                <IntegrationsCard />

                <ErpQueueCard />

                <FakeCard />
            </div>
            <div className="man-bo-dashboard-items">
                <HealthCheckCard />

                <InvoicingCard />
            </div>
        </React.Fragment>
    );
};

export default withRouter(Dashboard);
