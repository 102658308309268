import React, { useState, useEffect } from 'react';
import Instances from './components/page/instances';
import Instance from './components/page/instance';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './components/page/dashboard';
import BreadCrumbs from './components/common/breadcrumbs';
import { Button, Icon } from 'semantic-ui-react';
import { authService } from './data/auth/authService';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import CacheManager from './utils/cache/CacheManager';
import { get, post } from './utils/http/HttpClient';
import CacheManagerProps from './utils/cache/CacheManagerProps';
import { Provider } from 'react-redux';
import { store } from './data/redux/store';
import Integrations from './components/page/integrations';
import Modules from './components/page/modules';
import ErpQueue from './components/page/erp-queue';
import Billing from './components/page/billing';
import SupplierOrders from './components/page/supplier-orders';
import Topbar from './components/page/topbar';

const initialAuthState = {
    token: null,
    validated: false,
};

const LoginForm = () => {
    const [loading, setLoading] = useState(false);

    const handleLoginWithGoogle = async () => {
        try {
            setLoading(true);

            const uri = await authService.getGoogleAuthUri();

            window.location = uri;
        } catch {
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
            <Button
                color="google plus"
                loading={loading}
                onClick={handleLoginWithGoogle}>
                <Icon name="google" />
                Log in
            </Button>
        </div>
    );
};

const App = ({ history }) => {
    const [auth, setAuth] = useState(initialAuthState);

    const { token } = qs.parse(history.location.search);

    useEffect(() => {
        const persistToken = async () => {
            if (token) {
                await CacheManager.put(CacheManagerProps.TOKEN, token);

                setAuth({
                    ...auth,
                    token,
                });

                window.location = window.location.href.split('?')[0];
            }
        };

        persistToken();
    }, [token]);

    useEffect(() => {
        const validateSession = async () => {
            const jwt = await CacheManager.get(CacheManagerProps.TOKEN);

            if (!jwt) {
                setAuth({
                    ...auth,
                    validated: true,
                });
            }

            try {
                const { email } = await get('/keepalive', jwt);
                setAuth({
                    ...auth,
                    validated: true,
                    token: jwt,
                    email,
                });
            } catch {
                setAuth({
                    ...auth,
                    validated: true,
                });
            }
        };

        validateSession();
    }, []);

    const logout = async () => {
        try {
            await post('/auth/logout');
            setAuth({
                ...auth,
                validated: true,
                token: null,
            });
        } catch {
            setAuth({
                ...auth,
                validated: true,
                token: null,
            });
        }
    };

    if (!auth.validated) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 20,
                }}>
                Initializing...
            </div>
        );
    }

    return (
        <div className="man-app">
            {!auth.token ? (
                <LoginForm />
            ) : (
                <Provider store={store}>
                    <Topbar onLogout={logout} email={auth.email} />
                    
                    <div>
                        <BreadCrumbs />
                    </div>

                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/instances" component={Instances} />
                        <Route path="/instances/:id" component={Instance} />
                        <Route path="/integrations" component={Integrations} />
                        <Route path="/modules" component={Modules} />
                        <Route path="/erp" component={ErpQueue} />
                        <Route path="/billing" component={Billing} />
                        <Route path="/supplier-orders" component={SupplierOrders} />
                        <Route exact path="/index.html" component={Dashboard} />
                    </Switch>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '40px 0 0 0',
                        }}>
                        <img
                            style={{
                                width: 200,
                            }}
                            src="https://d3vp3ww4kx514t.cloudfront.net/assets/trukks/Trukks_Logo_Master-1.svg"
                        />
                    </div>
                </Provider>
            )}
        </div>
    );
};

export default withRouter(App);
