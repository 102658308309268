import React, { useEffect, useState } from 'react';
import { healthService } from '../../../../data/health/healthService';
import { DashboardCard } from '../DashboardCard';

const ServiceRow = ({ id, health = {} }) => {
    const data = health && health.data ? JSON.parse(health.data) : {};

    return (
        <tr>
            <td>
                <b>{id}</b>
                {data.timeout && (
                    <div style={{ fontSize: '0.8em', opacity: 0.5 }}>
                        {data.timeout} ms
                    </div>
                )}
            </td>
            <td style={{ textAlign: 'right' }}>
                <StatusBadge timestamp={health.timestamp} />
            </td>
        </tr>
    );
};

const StatusBadge = ({ timestamp }) => {
    if (!timestamp) {
        return (
            <div>
                UNKNOWN{' '}
                <span className="ui circular empty orange tiny label"></span>
            </div>
        );
    }

    const d = new Date();

    if ((d - timestamp) / 1000 / 60 > 3) {
        return (
            <div>
                DOWN <span className="ui circular empty red tiny label"></span>
                <div style={{ fontSize: '0.8em', opacity: 0.5 }}>
                    Last ok: {((d - timestamp) / 1000 / 60).toFixed(0)} min ago
                </div>
            </div>
        );
    }

    return (
        <div>
            UP <span className="ui circular empty green tiny label"></span>
        </div>
    );
};

export const HealthCheckCard = () => {
    const [isLoading, setLoading] = useState(false);
    const [health, setHealth] = useState({});
    const [state, setState] = useState('closed');

    const fetchHealth = async () => {
        try {
            setLoading(true);

            const response = await healthService.getHealth();

            setHealth(response);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHealth();
    }, []);

    return (
        <DashboardCard text="Health" isLoading={isLoading}>
            <table className="ui tiny compact table unstackable selectable">
                <tbody>
                    {Object.keys(health)
                        .sort((k1, k2) => (k1 > k2 ? 1 : -1))
                        .slice(0, 5)
                        .map(id => (
                            <ServiceRow key={id} id={id} health={health[id]} />
                        ))}

                    <tr className="trks-secondary-text">
                        <td>
                            {state === 'closed' && (
                                <React.Fragment>
                                    {Object.keys(health).length - 5} more
                                </React.Fragment>
                            )}
                        </td>
                        <td
                            style={{
                                textAlign: 'right',
                            }}>
                            {state === 'closed' && (
                                <a onClick={() => setState('open')}>EXPAND</a>
                            )}
                            {state === 'open' && (
                                <a onClick={() => setState('closed')}>
                                    COLLAPSE
                                </a>
                            )}
                        </td>
                    </tr>

                    {state === 'open' && (
                        <React.Fragment>
                            {Object.keys(health)
                                .sort((k1, k2) => (k1 > k2 ? 1 : -1))
                                .slice(5)
                                .map(id => (
                                    <ServiceRow
                                        key={id}
                                        id={id}
                                        health={health[id]}
                                    />
                                ))}
                        </React.Fragment>
                    )}
                </tbody>
            </table>

            <div style={{ textAlign: 'center' }}>
                <a onClick={fetchHealth}>RELOAD</a>
            </div>
        </DashboardCard>
    );
};
