import React, { useEffect, useState } from 'react';
import BottomSheet from 'react-swipeable-bottom-sheet';
import {
    Button,
    Divider,
    Dropdown,
    Form,
    Header,
    Image,
} from 'semantic-ui-react';
import { instanceService } from '../../../data/instances/instanceService';

const statuses = [
    { key: 'STATUS_ACTIVE', value: 'STATUS_ACTIVE', text: 'Active' },
    { key: 'STATUS_INACTIVE', value: 'STATUS_INACTIVE', text: 'Inactive' },
];

const isJsonString = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const formatJsonString = str => {
    let data = null;

    if (str) {
        try {
            data = JSON.parse(str);
        } catch {}
    }

    return data ? JSON.stringify(data, null, 2) : str;
};

const UpdateConfigurationBottomSheetContent = ({
    instanceId,
    instanceName,
    configuration,
    onSave,
    loading,
}) => {
    const [conf, setConf] = useState('');

    useEffect(() => {
        setConf(configuration || '');
    }, [instanceId, configuration]);

    const ok = isJsonString(conf);

    return (
        <div
            style={{
                minHeight: 400,
                maxWidth: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 40,
            }}>
            <Form>
                <React.Fragment>
                    <Header size="tiny">Update configuration</Header>
                    <Divider
                        style={{
                            marginTop: 0,
                        }}
                    />
                </React.Fragment>

                <Form.Field>
                    <label>Instance</label>
                    {`${instanceName} (${instanceId})`}
                </Form.Field>

                <Form.Field>
                    <label>Configuration</label>
                    <textarea
                        onChange={e => setConf(e.target.value)}
                        value={formatJsonString(conf)}
                    />
                </Form.Field>

                <Form.Field>
                    {!ok && (
                        <div className="trks-secondary-text red text ui">
                            Invalid JSON
                        </div>
                    )}
                    <Button
                        fluid
                        color="instagram"
                        loading={loading}
                        onClick={() =>
                            ok && onSave(instanceId, JSON.parse(conf))
                        }>
                        Save
                    </Button>
                </Form.Field>
            </Form>
        </div>
    );
};

const InstanceForm = ({
    id,
    displayName,
    configuration,
    status,
    workOrderConfiguration,
    businessObjectConfiguration,
    onSave,
    onChange,
}) => {
    const [savingStatus, setSavingStatus] = useState(false);
    const [saving, setSaving] = useState(false);
    const [updating, setUpdating] = useState(null);

    let { logoUrl } = configuration;

    const handleChange = (name, value) => {
        onChange(name, value);
    };

    const handleUpdateConfiguration = async (instanceId, configuration) => {
        try {
            setSaving(true);

            await updating.fn(instanceId, configuration);

            onChange(updating.key, configuration);
            setUpdating(null);
        } catch (err) {
            console.error(err);
            alert(`Failed to update configuration for ${instanceId}`);
        } finally {
            setSaving(false);
        }
    };

    const handleSaveStatus = async () => {
        try {
            setSavingStatus(true);

            await instanceService.updateStatus(id, status);
        } catch (err) {
            console.error(err);
            alert('error saving status');
        } finally {
            setSavingStatus(false);
        }
    };

    return (
        <div>
            <div className="man-image-container">
                <Image src={logoUrl} />
            </div>
            <h1>{displayName}</h1>

            <Form style={{ marginTop: 20 }}>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Status</label>
                        <Dropdown
                            search
                            selection
                            value={status}
                            onChange={(e, { value }) =>
                                handleChange('status', value)
                            }
                            options={statuses}
                        />
                        <Button
                            style={{
                                float: 'right',
                            }}
                            color="instagram"
                            onClick={handleSaveStatus}
                            loading={savingStatus}>
                            Save status
                        </Button>
                    </Form.Field>
                    <Form.Field>
                        <label>Configurations</label>
                        <Divider></Divider>
                        <div>
                            <b>
                                <a
                                    onClick={() =>
                                        setUpdating({
                                            configuration:
                                                JSON.stringify(configuration),
                                            fn: instanceService.updateConf,
                                            key: 'configuration',
                                        })
                                    }>
                                    EDIT Configuration
                                </a>
                            </b>
                        </div>

                        <Divider></Divider>

                        <div>
                            <b>
                                <a
                                    onClick={() =>
                                        setUpdating({
                                            configuration: JSON.stringify(
                                                businessObjectConfiguration
                                            ),
                                            fn: instanceService.updateBoConf,
                                            key: 'businessObjectConfiguration',
                                        })
                                    }>
                                    EDIT Business Object Configuration
                                </a>
                            </b>
                        </div>

                        <Divider></Divider>
                        <div>
                            <b>
                                <a
                                    onClick={() =>
                                        setUpdating({
                                            configuration: JSON.stringify(
                                                workOrderConfiguration
                                            ),
                                            fn: instanceService.updateWoConf,
                                            key: 'workOrderConfiguration',
                                        })
                                    }>
                                    EDIT Work Order Configuration
                                </a>
                            </b>
                        </div>
                    </Form.Field>
                </Form.Group>
            </Form>

            <BottomSheet open={!!updating} onChange={() => setUpdating(null)}>
                <UpdateConfigurationBottomSheetContent
                    instanceId={id}
                    instanceName={displayName}
                    loading={saving}
                    configuration={updating && updating.configuration}
                    onSave={handleUpdateConfiguration}
                />
            </BottomSheet>
        </div>
    );
};

export default InstanceForm;
