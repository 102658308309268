import React, { useEffect, useState } from 'react';
import {
    Area,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Line,
    ComposedChart,
} from 'recharts';
import { statsService } from '../../../../data/stats/statsService';
import { DashboardCard } from '../DashboardCard';

const formatPriceSum = (value, currency) => {
    let val = parseFloat(value || 0);

    if (isNaN(val)) {
        val = 0;
    }

    return `${formatDecimals(val.toFixed(0))}${currency ? ` ${currency}` : ''}`;
};

const formatPercent = value => {
    let val = parseFloat(value || 0);

    if (isNaN(val)) {
        val = 0;
    }

    return `${formatDecimals(val.toFixed(0))}%`;
};

const formatDecimals = (value, separator = ' ') => {
    if (!value) {
        return '';
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

const groupBy = (array, key) =>
    array.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

const CustomTooltip = ({ active, payload, label, type }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    padding: 20,
                    border: 'var(--default-border)',
                    boxShadow: 'var(--default-shadow)',
                    borderRadius: 4,
                }}>
                <b>{`${label}${payload[0].payload.isProjection ? ' (projected)' : ''}`}</b>

                <table className="ui compact table">
                    <tbody>
                        <tr>
                            <td>Instances</td>
                            <td
                                style={{
                                    color: '#E2856E',
                                }}>
                                <b>{payload[0].payload.instanceCount}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>{type === 'month' ? 'MRR' : 'ARR'}</td>
                            <td
                                style={{
                                    color: '#35CE8D',
                                }}>
                                <b>
                                    {formatPriceSum(
                                        payload[0].payload
                                            .agreementInvoicingTotal,
                                        'SEK'
                                    )}

                                    <span
                                        className="trks-secondary-text"
                                        style={{
                                            marginLeft: 8,
                                            color:
                                                payload[0].payload
                                                    .agreementInvoicingGrowth >
                                                0
                                                    ? 'var(--color-green)'
                                                    : 'var(--color-red)',
                                        }}>
                                        {`(${
                                            payload[0].payload
                                                .agreementInvoicingGrowth > 0
                                                ? '+'
                                                : ''
                                        }${formatPercent(
                                            payload[0].payload
                                                .agreementInvoicingGrowth
                                        )})`}
                                    </span>
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>Other</td>
                            <td
                                style={{
                                    color: '#BCD8B7',
                                }}>
                                <b>
                                    {formatPriceSum(
                                        payload[0].payload.otherInvoicingTotal,
                                        'SEK'
                                    )}
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>
                                <b>
                                    {formatPriceSum(
                                        payload[0].payload
                                            .agreementInvoicingTotal +
                                            payload[0].payload
                                                .otherInvoicingTotal,
                                        'SEK'
                                    )}
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>New instances</td>
                            <td
                                style={{
                                    color: '#779FA1',
                                }}>
                                <b>{payload[0].payload.newInstanceCount}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Churn</td>
                            <td
                                style={{
                                    color:
                                        payload[0].payload.churnInstanceCount >
                                        0
                                            ? '#FF6542'
                                            : 'black',
                                }}>
                                <b>{payload[0].payload.churnInstanceCount}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
};

const InvoicingCard = () => {
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('month');
    const [agreementInvoicing, setAgreementInvoicing] = useState({
        monthly: [],
        yearly: [],
    });
    const [otherInvoicing, setOtherInvoicing] = useState({
        monthly: [],
        yearly: [],
    });
    const [instances, setInstances] = useState({
        monthly: [],
        yearly: [],
        monthlyChurn: [],
        yearlyChurn: [],
    });

    useEffect(() => {
        const fetchInvoicingDetails = async () => {
            try {
                setLoading(true);

                const agreement = await statsService.fetchAgreementInvoicing();
                const other = await statsService.fetchOtherInvoicing();
                const instanceCounts = await statsService.fetchInstances();

                setAgreementInvoicing(agreement);
                setOtherInvoicing(other);
                setInstances(instanceCounts);
            } catch (err) {
                console.log(err);
                alert(err);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoicingDetails();
    }, []);

    const grouped = groupBy(
        [
            ...agreementInvoicing.monthly.map(r => {
                r.type = 'agreement';
                const [y, m] = r.month.split('-');
                r.month = `${y}-${m.padStart(2, '0')}`;
                return r;
            }),
            ...otherInvoicing.monthly.map(r => {
                r.type = 'other';
                const [y, m] = r.month.split('-');
                r.month = `${y}-${m.padStart(2, '0')}`;
                return r;
            }),
            ...instances.monthly.map(r => {
                r.type = 'instanceCount';
                const [y, m] = r.month.split('-');
                r.month = `${y}-${m.padStart(2, '0')}`;
                return r;
            }),
            ...instances.monthlyChurn.map(r => {
                r.type = 'churnInstanceCount';
                const [y, m] = r.month.split('-');
                r.month = `${y}-${m.padStart(2, '0')}`;
                return r;
            }),
        ],
        'month'
    );

    let instanceTotal = 0;
    let lastAgreementInvoicingTotal = 0;

    const data = Object.keys(grouped)
        .sort()
        .map(month => {
            const rows = grouped[month];

            const agreementInvocingForMonth = rows.find(
                r => r.type === 'agreement'
            );
            const otherInvoicingForMonth = rows.find(r => r.type === 'other');

            const instancesForMonth = rows.find(
                r => r.type === 'instanceCount'
            );

            const churnInstancesForMonth = rows.find(
                r => r.type === 'churnInstanceCount'
            );

            instanceTotal =
                instanceTotal +
                (instancesForMonth ? instancesForMonth.totalInstances : 0) -
                (churnInstancesForMonth
                    ? churnInstancesForMonth.totalInstances
                    : 0);

            const agreementInvoicingTotal = agreementInvocingForMonth
                ? agreementInvocingForMonth.totalInvoiced
                : 0;

            const isProjection = agreementInvocingForMonth
                ? agreementInvocingForMonth.projection
                : false;


            const agreementInvoicingGrowth =
                ((agreementInvoicingTotal - lastAgreementInvoicingTotal) /
                    lastAgreementInvoicingTotal) *
                100;

            lastAgreementInvoicingTotal = agreementInvoicingTotal;

            return {
                month,
                isProjection,
                agreementInvoicingTotal: agreementInvocingForMonth
                    ? agreementInvocingForMonth.totalInvoiced
                    : 0,
                agreementInvoicingGrowth,
                otherInvoicingTotal: otherInvoicingForMonth
                    ? otherInvoicingForMonth.totalInvoiced
                    : 0,
                instanceCount: instanceTotal,
                newInstanceCount: instancesForMonth
                    ? instancesForMonth.totalInstances
                    : 0,
                churnInstanceCount: churnInstancesForMonth
                    ? churnInstancesForMonth.totalInstances
                    : 0,
            };
        });

    const groupedYearly = groupBy(
        [
            ...agreementInvoicing.yearly.map(r => {
                r.type = 'agreement';
                return r;
            }),
            ...otherInvoicing.yearly.map(r => {
                r.type = 'other';
                return r;
            }),
            ...instances.yearly.map(r => {
                r.type = 'instanceCount';
                return r;
            }),
            ...instances.yearlyChurn.map(r => {
                r.type = 'churnInstanceCount';
                return r;
            }),
        ],
        'year'
    );

    let yearlyInstanceTotal = 0;
    lastAgreementInvoicingTotal = 0;

    const yearlyData = Object.keys(groupedYearly)
        .sort()
        .map(year => {
            const rows = groupedYearly[year];

            const agreementInvocingForYear = rows.find(
                r => r.type === 'agreement'
            );
            const otherInvoicingForYear = rows.find(r => r.type === 'other');

            const instancesForYear = rows.find(r => r.type === 'instanceCount');

            const churnInstancesForYear = rows.find(
                r => r.type === 'churnInstanceCount'
            );

            yearlyInstanceTotal =
                yearlyInstanceTotal +
                (instancesForYear ? instancesForYear.totalInstances : 0) -
                (churnInstancesForYear
                    ? churnInstancesForYear.totalInstances
                    : 0);

            const agreementInvoicingTotal = agreementInvocingForYear
                    ? agreementInvocingForYear.totalInvoiced
                    : 0;
    
                const agreementInvoicingGrowth =
                    ((agreementInvoicingTotal - lastAgreementInvoicingTotal) /
                        lastAgreementInvoicingTotal) *
                    100;
    
                lastAgreementInvoicingTotal = agreementInvoicingTotal;

            return {
                year,
                agreementInvoicingTotal,
                agreementInvoicingGrowth,
                otherInvoicingTotal: otherInvoicingForYear
                    ? otherInvoicingForYear.totalInvoiced
                    : 0,
                instanceCount: yearlyInstanceTotal,
                newInstanceCount: instancesForYear
                    ? instancesForYear.totalInstances
                    : 0,
                churnInstanceCount: churnInstancesForYear
                    ? churnInstancesForYear.totalInstances
                    : 0,
            };
        });

    return (
        <DashboardCard
            text="Growth"
            isLoading={loading}
            style={{
                maxHeight: 360,
                height: 360,
                width: '100%',
            }}
            headerExtra={
                <a onClick={() => setType(type === 'month' ? 'year' : 'month')}>
                    {type === 'month' ? 'Show YoY' : 'Show MoM'}
                </a>
            }>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={'100%'}
                    height={320}
                    data={type === 'year' ? yearlyData : data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={type} />
                    <YAxis
                        yAxisId="left"
                        tickFormatter={tick => formatPriceSum(tick, '')}
                    />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip content={<CustomTooltip type={type} />} />
                    <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="instanceCount"
                        stroke="#E2856E"
                    />
                    <Area
                        type="monotone"
                        dataKey="agreementInvoicingTotal"
                        stackId="1"
                        stroke="#35CE8D"
                        fill="#35CE8D"
                        yAxisId="left"
                    />
                    <Area
                        type="monotone"
                        dataKey="otherInvoicingTotal"
                        stackId="1"
                        stroke="#BCD8B7"
                        fill="#BCD8B7"
                        yAxisId="left"
                    />

                    <Legend
                        formatter={(value, entry, index) => {
                            const { color } = entry;

                            if (value === 'agreementInvoicingTotal') {
                                return (
                                    <span style={{ color }}>
                                        <b>{type === 'month' ? 'MRR' : 'ARR'}</b>
                                    </span>
                                );
                            }

                            if (value === 'otherInvoicingTotal') {
                                return (
                                    <span style={{ color }}>
                                        <b>Other</b>
                                    </span>
                                );
                            }

                            if (value === 'instanceCount') {
                                return (
                                    <span style={{ color }}>
                                        <b>Instances</b>
                                    </span>
                                );
                            }

                            return <span style={{ color }}>{value}</span>;
                        }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </DashboardCard>
    );
};

export default InvoicingCard;
