import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchModules } from '../../../data/modules/moduleActions';
import { Table, Input } from 'semantic-ui-react';
import JsonView from '../../common/json-view';

const Modules = ({ modules, fetchModules }) => {

    const [search, setSearch] = useState('');

    useEffect(() => {
        modules.length === 0 && fetchModules();
    }, []);


    const appModules = search ? modules.filter(appModule => appModule.id.toLowerCase().indexOf(search.toLowerCase()) > -1) :
        modules;

    return (
        <React.Fragment>
            <div style={{ marginBottom: 10 }}>
                <Input icon="search"
                    autoFocus
                    iconPosition="left"
                    placeholder="Search..."
                    value={search}
                    onChange={(e, { value }) => setSearch(value)} />
            </div>

            <Table className="man-form-table" celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Module Id</Table.HeaderCell>
                        <Table.HeaderCell>Configuration</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {appModules.map(({ id, instanceCount, configuration, instances }) => (
                        <Table.Row key={id}>
                            <Table.Cell>
                                <b>{id}</b>
                                <br />
                                <a onClick={() => alert(instances.join(`\n`))}>{`${instanceCount} instances`}</a>
                            </Table.Cell>
                            <Table.Cell>
                                <JsonView json={configuration} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </ React.Fragment>
    )
}

const mapStateToProps = state => ({
    modules: state.modules.modules,
    isLoadingModules: state.modules.isLoading
})

const mapDispatchToProps = {
    fetchModules
}

export default connect(mapStateToProps, mapDispatchToProps)(Modules);