import { get, post } from '../../utils/http/HttpClient';
import qs from 'query-string';

export const integrationsService = {

    fetchErrors: async (topic, limit) => {
        const response = await get(`/integration/errors?topic=${topic}&limit=${limit}`);

        return response;
    },

    fetchLogs: async (instanceId, limit) => {
        const response = await get(`/integration/logs?instanceId=${instanceId}&limit=${limit}`);

        return response;
    },

    fetchErpQueue: async (instanceId, limit) => {
        const request = {
            instanceId,
            limit
        }

        const response = await get(`/integration/queue?${qs.stringify(request)}`);

        return response;
    },
    fetchErpQueueCount: async () => {
        const response = await get('/integration/queueCount');

        return response;
    },
    ignoreErpQueueItem: async item => {
        await post('/integration/queue', { ...item, action: 'ignore' });
    },
    retryErpQueueItem: async item => {
        await post('/integration/queue', { ...item, action: 'retry' });
    }

}