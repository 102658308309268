import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BottomSheet from 'react-swipeable-bottom-sheet';
import {
    Button,
    Dropdown,
    Form,
    Header,
    Input,
    Label,
    Table,
} from 'semantic-ui-react';
import { Divider, LineLoader } from 'trukks-lib';
import { instanceService } from '../../../data/instances/instanceService';

const statuses = [
    { key: 'STATUS_ACTIVE', value: 'STATUS_ACTIVE', text: 'Active' },
    { key: 'STATUS_INACTIVE', value: 'STATUS_INACTIVE', text: 'Inactive' },
    {
        key: 'STATUS_REGISTERED',
        value: 'STATUS_REGISTERED',
        text: 'Registered',
    },
];

const UserBottomSheetContent = ({
    instanceId,
    user,
    onChange,
    onSave,
    loading,
}) => {
    const [loadingOpenSessions, setLoadingOpenSessions] = useState(true);
    const [openSessions, setOpenSessions] = useState([]);
    const [savingPassword, setSavingPassword] = useState(false);
    const [password, setPassword] = useState('');

    useEffect(() => {
        const fetchOpenSessions = async () => {
            try {
                setLoadingOpenSessions(true);
                const { sessions } =
                    await instanceService.fetchUserOpenSessions(
                        instanceId,
                        user.id
                    );

                setOpenSessions(sessions);
            } catch (err) {
                console.log(err);
            } finally {
                setLoadingOpenSessions(false);
            }
        };

        fetchOpenSessions();
    }, [user.id]);

    const handleChangePassword = async newPassword => {
        try {
            setSavingPassword(true);

            await instanceService.changeUserPassword(
                instanceId,
                user.id,
                newPassword
            );
        } catch (err) {
            console.error(err);
            alert(`Failed to update password`);
        } finally {
            setSavingPassword(false);
        }
    };

    return (
        <Form>
            <React.Fragment>
                <Header size="tiny">{user && user.fullName}</Header>

                <Divider
                    style={{
                        marginTop: 0,
                    }}
                />
            </React.Fragment>

            <Form.Input
                label="Email"
                type="text"
                value={(user && user.email) || ''}
                onChange={(e, { value }) =>
                    onChange({
                        ...user,
                        email: value,
                    })
                }
            />

            <Form.Input
                label="Microsoft Email"
                type="text"
                value={(user && user.microsoftAuthEmail) || ''}
                onChange={(e, { value }) =>
                    onChange({
                        ...user,
                        microsoftAuthEmail: value,
                    })
                }
            />

            <Form.Input
                label="Google email"
                type="text"
                value={(user && user.googleAuthEmail) || ''}
                onChange={(e, { value }) =>
                    onChange({
                        ...user,
                        googleAuthEmail: value,
                    })
                }
            />

            <Form.Field>
                <label>Status</label>
                <Dropdown
                    search
                    selection
                    value={user && user.status}
                    onChange={(e, { value }) =>
                        onChange({
                            ...user,
                            status: value,
                        })
                    }
                    options={statuses}
                />
            </Form.Field>

            <Header size="tiny">Set new password</Header>
            <Form.Group widths="equal">
                <Form.Input
                    placeholder="password"
                    type="text"
                    value={password}
                    onChange={(e, { value }) => setPassword(value)}
                />

                <Form.Field>
                    <Button
                        fluid
                        color="red"
                        loading={savingPassword}
                        onClick={() => handleChangePassword(password)}>
                        Set password
                    </Button>
                </Form.Field>
            </Form.Group>

            <Header size="tiny">Open sessions</Header>

            <Divider
                style={{
                    marginTop: 0,
                }}
            />

            {loadingOpenSessions && <LineLoader lines={4} />}

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>Host</Table.HeaderCell>
                        <Table.HeaderCell>Created (UTC)</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {openSessions.map(os => (
                        <Table.Row key={os.id}>
                            <Table.Cell>{os.id}</Table.Cell>
                            <Table.Cell>{os.host}</Table.Cell>
                            <Table.Cell>
                                {moment(os.createTs).format('YYYY-MM-DD HH:mm')}
                            </Table.Cell>
                            <Table.Cell>
                                <Button
                                    type="negative"
                                    icon="close"
                                    onClick={() =>
                                        alert('not implemented yet')
                                    }>
                                    Close session
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

            <Form.Field>
                <Button
                    fluid
                    color="instagram"
                    loading={loading}
                    onClick={onSave}>
                    Save
                </Button>
            </Form.Field>
        </Form>
    );
};

const UserForm = ({ instanceId, users, onChange, onSave, acls }) => {
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [editingUser, setEditingUser] = useState(null);

    const handleSaveUser = async () => {
        try {
            setLoading(true);
            await instanceService.patchUser(instanceId, editingUser);

            const newUsers = [...users].map(user => {
                if (user.id === editingUser.id) {
                    return editingUser;
                }

                return user;
            });

            onChange(newUsers);
            setEditingUser(null);
        } catch (err) {
            console.error(err);
            alert(err);
        } finally {
            setLoading(false);
        }
    };

    const filteredUsers = search
        ? users.filter(
              user =>
                  user.fullName.toLowerCase().indexOf(search.toLowerCase()) >
                      -1 ||
                  user.email.toLowerCase().indexOf(search.toLowerCase()) > -1
          )
        : users;

    return (
        <React.Fragment>
            <BottomSheet
                open={!!editingUser}
                onChange={isOpen => !isOpen && setEditingUser(null)}>
                <div className="man-bo-bottomsheet-content">
                    {editingUser && (
                        <UserBottomSheetContent
                            instanceId={instanceId}
                            user={editingUser}
                            onChange={setEditingUser}
                            onSave={handleSaveUser}
                            loading={loading}
                        />
                    )}
                </div>
            </BottomSheet>

            <div style={{ marginBottom: 10 }}>
                <Input
                    icon="search"
                    autoFocus
                    iconPosition="left"
                    placeholder="Search..."
                    value={search}
                    style={{
                        opacity: editingUser ? 0 : 1,
                    }}
                    onChange={(e, { value }) => setSearch(value)}
                />
            </div>

            <Table className="man-form-table" celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Created</Table.HeaderCell>
                        <Table.HeaderCell>Access levels</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredUsers
                        .sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
                        .map(u => (
                            <Table.Row
                                key={u.id}
                                onClick={() => setEditingUser(u)}
                                style={{
                                    cursor: 'pointer',
                                }}>
                                <Table.Cell>{u.fullName}</Table.Cell>
                                <Table.Cell>{u.email}</Table.Cell>
                                <Table.Cell>
                                    <Label
                                        size="tiny"
                                        color={
                                            u.status === 'STATUS_ACTIVE'
                                                ? 'green'
                                                : 'orange'
                                        }>
                                        {u.status.split('_')[1]}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>{u.createTs}</Table.Cell>
                                <Table.Cell>
                                    <div>
                                        {u.acls.map(a => {
                                            const acl = acls.find(
                                                acl => acl.id === a
                                            );

                                            return (
                                                <div key={a}>
                                                    {acl && acl.displayName}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};

export default UserForm;
