import React from 'react';

const JsonView = ({ json, jsonString }) => {

    let data = null;

    if (jsonString) {
        try {
            data = JSON.parse(jsonString);
        } catch {
            
        }
    }

    return (
        <div style={{
            fontSize: '0.8em',
            overflowX: json || data ? 'scroll' : 'none'
        }}>
            <pre>{json && JSON.stringify(json, null, 2)}</pre>
            <pre>{data && JSON.stringify(data, null, 2)}</pre>
        </div>
    )
}

export default JsonView;