import { get } from "../../utils/http/HttpClient";
import qs from 'query-string';

export const moduleService = {

    fetch: async () => {
        const { modules } = await get('/module');
        return modules;
    },
    fetchSupplierOrder: async (supplierOrderId, instanceId, supplierId) => {
        const request = {
            instanceId,
            supplierId
        }

        const { orders } = await get(`/module/supplier-order/${supplierOrderId}?${qs.stringify(request)}`);

        return orders;
    }

}